import React from 'react';
import posed from 'react-pose';
import { css } from 'emotion';
import { StaticQuery, graphql } from 'gatsby';

import closeButton from '../images/Cross.png';

import { HTMLContent } from './content';
import { STYLES, BREAKPOINTS } from './constants';

const { small, medium } = STYLES;

const infoStyles = css`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 5;
  height: 100%;
  width: 100%;
  color: white;

  .container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;

    background-color: black;
  }

  .close-container {
    position: absolute;
    top: 0;
    right: 0;
    width: 50vw;
    height: 100vh;

    background-color: transparent;
    cursor: pointer;
    display: none;
  }

  article {
    font-family: 'Fortescue Pro', serif;
    letter-spacing: 0.1rem;
    font-size: 16px;
    line-height: 28px;
    text-align: justify;

    width: 290px;
    padding-top: 21vh;
    margin: 0 auto;
  }

  footer {
    position: absolute;
    bottom: ${small.borderPadding};
    left: ${small.borderPadding};

    a {
      font-family: 'Apercu Pro', sans-serif;
      font-size: 12px;
      letter-spacing: 0.1rem;

      color: white;
      text-decoration: none;
    }
  }

  nav {
    position: absolute;
    top: 80px;
    left: ${small.closeLeftPadding};

    .close {
      cursor: pointer;

      img {
        width: 15px;
      }
    }
  }

  @media (min-width: ${BREAKPOINTS.medium}) {
    footer {
      bottom: ${medium.borderPadding};
      left: ${medium.borderPadding};
    }

    nav {
      left: ${medium.closeLeftPadding};
    }
  }

  @media (min-width: ${BREAKPOINTS.large}) {
    .container {
      width: 50vw;
    }

    .close-container {
      display: block;
    }
  }
`;

const InfoPage = posed.section({
  open: {
    x: '0%',
    opacity: 1,
    transition: { ease: 'easeOut', duration: 700 }
  },
  closed: {
    x: '-100%',
    opacity: 0,
    transition: { ease: 'easeIn', duration: 600 }
  }
});

const InfoContent = posed.article({
  open: {
    opacity: 1,
    transition: { ease: 'easeOut', duration: 4000 }
  },
  closed: {
    opacity: 0,
    transition: { ease: 'easeIn', duration: 1000 }
  }
});

function Info({ isOpen, close }) {
  return (
    <StaticQuery
      query={graphql`
        {
          infoPage: allMarkdownRemark(
            filter: { frontmatter: { templateKey: { eq: "info-page" } } }
          ) {
            edges {
              node {
                html
              }
            }
          }
        }
      `}
      render={data => {
        const {
          infoPage: {
            edges: [
              {
                node: { html }
              }
            ]
          }
        } = data;
        return (
          <InfoPage
            pose={isOpen ? 'open' : 'closed'}
            className={`${infoStyles} info-content`}
          >
            <section className="container">
              <InfoContent>
                <HTMLContent content={html} />
              </InfoContent>

              <nav>
                <div className="close" onClick={close}>
                  <img alt="Close" src={closeButton} />
                </div>
              </nav>
            </section>
            <section className="close-container" onClick={close} />
          </InfoPage>
        );
      }}
    />
  );
}

export default Info;
