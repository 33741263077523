import React from 'react';
import { css } from 'emotion';
import { STYLES, BREAKPOINTS } from './constants';

const { small, medium } = STYLES;

const footerStyles = css`
  footer {
    position: absolute;
    bottom: ${small.borderPadding};
    left: ${small.borderPadding};
    z-index: 100;
  }

  a {
    font-family: 'Apercu Pro', sans-serif;
    font-size: 12px;
    letter-spacing: 0.1rem;

    color: white;
    text-decoration: none;
  }

  @media (min-width: ${BREAKPOINTS.medium}) {
    footer {
      bottom: ${medium.borderPadding};
      left: ${medium.borderPadding};
    }
  }
`;

function Insta() {
  return (
    <section className={`${footerStyles}`}>
      <footer>
        <a
          href="https://www.instagram.com/akme.studio/"
          target="_blank"
          rel="noopener noreferrer"
        >
          @akme.studio
        </a>
      </footer>
    </section>
  );
}

export default Insta;
