import React from 'react';
import PropTypes from 'prop-types';

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.min.css';
import SwiperCore, { Autoplay } from 'swiper';

export default function Items(props) {
  const { portfolioItems } = props;
  SwiperCore.use([Autoplay]);

  return (
    <div className="portfolio">
      <div
        onMouseEnter={() => console.log('enter')}
        onMouseLeave={() => console.log('left')}
      >
        <Swiper
          spaceBetween={30}
          centeredSlides={true}
          speed={0}
          autoplay={{
            delay: 400,
            disableOnInteraction: false,
            pauseOnMouseEnter: true
          }}
        >
          {portfolioItems.map((portfolioItem, index) => {
            const {
              title,
              image
              //video,
              //mobileVideo,
              //imageSizes,
              //mobileImageSizes,
              //isPlaying
            } = portfolioItem;

            return (
              <SwiperSlide key={image}>
                <img src={image} alt={title} />
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
    </div>
  );
}

Items.propTypes = {
  portfolioItems: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.string,
      mobileImage: PropTypes.string,
      video: PropTypes.string,
      mobileVideo: PropTypes.string,
      layout: PropTypes.string,
      isPlaying: PropTypes.bool
    })
  ),
  portfolioImageIndex: PropTypes.number
};
