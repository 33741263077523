import React, { Component } from 'react';
import Layout from '../components/layout';

class Index extends Component {
  render() {
    return <Layout />;
  }
}

export default Index;
