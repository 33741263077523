const CAPTURE_EVENT = true;
const KEYDOWN = 'keydown';
const KEYS = {
  ArrowUp: 'ArrowUp',
  ArrowDown: 'ArrowDown',
  ArrowLeft: 'ArrowLeft',
  ArrowRight: 'ArrowRight'
};

const createArrowKeyEvents = ({
  onUp = () => {},
  onDown = () => {},
  onLeft = () => {},
  onRight = () => {}
}) => {
  // Based off https://stackoverflow.com/a/40648033/111884
  const addArrowKeysListener = event => {
    if (event.defaultPrevented) {
      return; // Do nothing if the event was already processed
    }

    switch (event.key) {
      case KEYS.ArrowUp:
        onUp();
        break;
      case KEYS.ArrowDown:
        onDown();
        break;
      case KEYS.ArrowLeft:
        onLeft();
        break;
      case KEYS.ArrowRight:
        onRight();
        break;
      default:
        return;
    }

    // Cancel the default action to avoid it being handled twice
    event.preventDefault();
  };

  const init = () => {
    window.addEventListener(KEYDOWN, addArrowKeysListener, CAPTURE_EVENT);
  };

  const teardown = () => {
    window.removeEventListener(KEYDOWN, addArrowKeysListener, CAPTURE_EVENT);
  };

  return {
    init,
    teardown
  };
};

export default createArrowKeyEvents;
