import React, { Component } from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { find, cloneDeep } from 'lodash';

import createArrowKeyEvents from '../../lib/create-arrow-key-events';
//import PortfolioNav from './nav';
import PortfolioItems from './items';

import { MOBILE_IMAGE_BREAKPOINT } from '../constants';

const getFilename = path => {
  if (!path) {
    return;
  }
  const [, , filename] = path.match(/^\/(.+\/)*(.+\..+)$/) || [];
  return filename;
};

function getRandomInt(max) {
  return Math.floor(Math.random() * Math.floor(max));
}

// HACK: Remove template entry, so that optional fields work
// when the data does not generate the correct graphql schema
// See https://github.com/gatsbyjs/gatsby/issues/3344
const PORTFOLIO_TEMPLATE_TITLE = 'Template (DO NOT DELETE)';
function cleanPortfolioItems(items) {
  return items.filter(({ title } = {}) => title !== PORTFOLIO_TEMPLATE_TITLE);
}

const getScreenWidth = () => {
  const body = document.getElementsByTagName('body')[0];
  const docElem = document.documentElement;
  return window.innerWidth || docElem.clientWidth || body.clientWidth;
};

const getNextIndex = ({ portfolioItems, currentImageIndex, screenWidth }) => {
  const nextIndex = (currentImageIndex + 1) % portfolioItems.length;
  const { mobileImage, image } = portfolioItems[nextIndex];
  if (screenWidth <= MOBILE_IMAGE_BREAKPOINT && !!image && !mobileImage) {
    return getNextIndex({
      portfolioItems,
      currentImageIndex: nextIndex,
      screenWidth
    });
  }
  return nextIndex;
};

const getPreviousIndex = ({
  portfolioItems,
  currentImageIndex,
  screenWidth
}) => {
  const previousIndex =
    currentImageIndex - 1 < 0
      ? portfolioItems.length - 1
      : currentImageIndex - 1;
  const { mobileImage, image } = portfolioItems[previousIndex];
  if (screenWidth <= MOBILE_IMAGE_BREAKPOINT && !!image && !mobileImage) {
    return getPreviousIndex({
      portfolioItems,
      currentImageIndex: previousIndex,
      screenWidth
    });
  }
  return previousIndex;
};

class Portfolio extends Component {
  constructor(props) {
    super(props);

    const { portfolioItems } = props;
    this.state = {
      // Set isPlaying to be false by default
      portfolioItems: portfolioItems.map(item => {
        const { video } = item;

        return !!video
          ? Object.assign(
              {},
              {
                isPlaying: false
              },
              item
            )
          : item;
      }),
      currentImageIndex: 0,
      maxImageIndex: portfolioItems.length - 1
    };
  }

  componentDidMount() {
    this.setState(({ portfolioItems }) => {
      const randomImageIndex = getNextIndex({
        portfolioItems,
        currentImageIndex: getRandomInt(portfolioItems.length - 1),
        screenWidth: getScreenWidth()
      });

      return {
        currentImageIndex: randomImageIndex
      };
    });

    // Set up arrow key events
    this.arrowKeyEvents = createArrowKeyEvents({
      onLeft: () => {
        this.handlePreviousPortfolioItem();
      },
      onRight: () => {
        this.handleNextPortfolioItem();
      }
    });
    this.arrowKeyEvents.init();
  }

  componentWillUnmount() {
    this.arrowKeyEvents.teardown();
  }

  handleNextPortfolioItem() {
    this.setState(({ currentImageIndex, portfolioItems }) => {
      const nextIndex = getNextIndex({
        portfolioItems,
        currentImageIndex,
        screenWidth: getScreenWidth()
      });

      let newPortfolioItems = portfolioItems;

      // Stop current video
      const { video: currentVideo } = portfolioItems[currentImageIndex];
      if (!!currentVideo) {
        newPortfolioItems = cloneDeep(newPortfolioItems);
        newPortfolioItems[currentImageIndex] = Object.assign(
          {},
          newPortfolioItems[currentImageIndex],
          {
            isPlaying: false
          }
        );
      }

      // Play next video
      const { video: nextVideo } = portfolioItems[nextIndex];
      if (!!nextVideo) {
        newPortfolioItems = cloneDeep(newPortfolioItems);
        newPortfolioItems[nextIndex] = Object.assign(
          {},
          newPortfolioItems[nextIndex],
          {
            isPlaying: true
          }
        );
      }

      return {
        portfolioItems: newPortfolioItems,
        currentImageIndex: nextIndex
      };
    });
  }

  handlePreviousPortfolioItem() {
    this.setState(({ currentImageIndex, portfolioItems }) => {
      const prevIndex = getPreviousIndex({
        portfolioItems,
        currentImageIndex,
        screenWidth: getScreenWidth()
      });

      let newPortfolioItems = portfolioItems;

      // Stop current video
      const { video: currentVideo } = portfolioItems[currentImageIndex];
      if (!!currentVideo) {
        newPortfolioItems = cloneDeep(newPortfolioItems);
        newPortfolioItems[currentImageIndex] = Object.assign(
          {},
          newPortfolioItems[currentImageIndex],
          {
            isPlaying: false
          }
        );
      }

      // Play previous video
      const { video: prevVideo } = portfolioItems[prevIndex];
      if (!!prevVideo) {
        newPortfolioItems = cloneDeep(newPortfolioItems);
        newPortfolioItems[prevIndex] = Object.assign(
          {},
          newPortfolioItems[prevIndex],
          {
            isPlaying: true
          }
        );
      }
      return {
        portfolioItems: newPortfolioItems,
        currentImageIndex: prevIndex
      };
    });
  }

  render() {
    const { portfolioItems, currentImageIndex } = this.state;
    return (
      <React.Fragment>
        <PortfolioItems
          portfolioItems={portfolioItems}
          currentImageIndex={currentImageIndex}
        />
        {/* <PortfolioNav
          onPrevious={this.handlePreviousPortfolioItem.bind(this)}
          onNext={this.handleNextPortfolioItem.bind(this)}
        /> */}
      </React.Fragment>
    );
  }
}

export default class PortfolioContainer extends Component {
  render() {
    return (
      <StaticQuery
        query={query}
        render={data => {
          const {
            portfolioPage: {
              edges: [
                {
                  node: {
                    frontmatter: { portfolioItems: portfolioItemsRaw }
                  }
                }
              ]
            },
            allImageSharp
          } = data;

          const portfolioImages = allImageSharp.edges.map(
            ({ node: { fluid: image } }) => {
              return image;
            }
          );
          const portfolioItems = cleanPortfolioItems(portfolioItemsRaw).map(
            item => {
              const { image, mobileImage } = item;
              const imageSizes = find(portfolioImages, {
                originalName: getFilename(image)
              });
              const mobileImageSizes = find(portfolioImages, {
                originalName: getFilename(mobileImage)
              });

              return {
                imageSizes,
                mobileImageSizes,
                ...item
              };
            }
          );

          return <Portfolio portfolioItems={portfolioItems} />;
        }}
      />
    );
  }
}

const query = graphql`
  {
    portfolioPage: allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "portfolio-page" } } }
    ) {
      edges {
        node {
          frontmatter {
            portfolioItems {
              title
              image
              mobileImage
              video
              mobileVideo
              layout
            }
          }
        }
      }
    }
    allImageSharp {
      edges {
        node {
          ... on ImageSharp {
            fluid(quality: 100, maxWidth: 1600) {
              src
              srcSet
              originalImg
              originalName
              aspectRatio
              sizes
            }
          }
        }
      }
    }
  }
`;
