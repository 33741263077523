import React, { Component } from 'react';
import { css } from 'emotion';

import Header from '../components/header';
import Info from '../components/info';
import Portfolio from '../components/portfolio';
import ComingSoon from '../components/coming-soon';
import Insta from '../components/insta';

import './main.css';

const appStyles = css`
  margin: 0;

  height: 100vh;
  display: flex;
  flex-direction: column;
`;

class Layout extends Component {
  constructor(props) {
    super(props);

    this.state = {
      infoIsOpen: false
    };
  }

  componentDidMount() {
    // Update `no-js` to `js` in html className once react is loaded
    const htmlElem = document.documentElement;
    htmlElem.className = htmlElem.className.replace(/no-js/, 'js');
  }

  handleToggleInfo() {
    this.setState(({ infoIsOpen }) => {
      return {
        infoIsOpen: !infoIsOpen
      };
    });
  }

  handleCloseInfo() {
    this.setState({
      infoIsOpen: false
    });
  }

  render() {
    return (
      <div className={appStyles}>
        <Header onInfoToggle={this.handleToggleInfo.bind(this)} />
        <Portfolio />
        <section className="body">
          <Info
            isOpen={this.state.infoIsOpen}
            close={this.handleCloseInfo.bind(this)}
          />
        </section>
        <Insta />
        <ComingSoon />
      </div>
    );
  }
}

export default Layout;
