import React from 'react';
import { css } from 'emotion';

import comingSoon from '../images/coming-soon.svg';
import { STYLES, BREAKPOINTS } from './constants';

const { small, medium } = STYLES;

const styles = css`
  .coming-soon {
    position: fixed;
    bottom: ${small.borderPadding};
    right: ${small.borderPadding};
    z-index: 0;

    img {
      width: 100px;
    }
  }

  .spin {
    animation: spin 60000ms linear infinite;
  }

  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }

  @media (min-width: ${BREAKPOINTS.medium}) {
    .coming-soon {
      bottom: ${medium.borderPadding};
      right: ${medium.borderPadding};
    }
  }
`;

export default function ComingSoon() {
  return (
    <div className={styles}>
      <div className={`coming-soon spin`}>
        <img alt="Website coming soon" src={comingSoon} />
      </div>
    </div>
  );
}
