import React from 'react';
import Helmet from 'react-helmet';
import { css } from 'emotion';
import { StaticQuery, graphql } from 'gatsby';

import logo from '../images/logo.svg';
import { STYLES, BREAKPOINTS } from './constants';

const { small, medium } = STYLES;

const headerStyles = css`
  z-index: 10;
  position: fixed;
  width: 100vw;

  nav {
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    align-items: baseline;
    color: white;

    a {
      color: white;
      text-decoration: none;
    }

    .info {
      font-family: 'Apercu Pro', sans-serif;
      letter-spacing: 0.1rem;
      font-size: 12px;

      cursor: pointer;

      padding-left: ${small.borderPadding};
      width: 100px;
    }

    .logo {
      padding-top: ${small.topPadding};

      img {
        width: 95px;
      }
    }

    .contact {
      font-family: 'Apercu Pro', sans-serif;
      letter-spacing: 0.1rem;
      font-size: 12px;
      text-align: right;

      padding-right: ${small.borderPadding};
      width: 100px;
    }
  }

  @media (min-width: ${BREAKPOINTS.medium}) {
    nav {
      .info {
        padding-left: ${medium.borderPadding};
      }

      .logo {
        padding-top: ${medium.topPadding};
      }

      .contact {
        padding-right: ${medium.borderPadding};
      }
    }
  }
`;

const handleInfoToggle = onInfoToggle => e => {
  e.preventDefault();

  onInfoToggle(e);
};

function Header({ onInfoToggle }) {
  return (
    <StaticQuery
      query={graphql`
        {
          site {
            siteMetadata {
              title
              description
              keywords
            }
          }
        }
      `}
      render={data => {
        const {
          site: { siteMetadata }
        } = data;
        const { title, description, keywords } = siteMetadata;

        return (
          <React.Fragment>
            <Helmet
              title={title}
              meta={[
                { name: 'description', content: description },
                { name: 'keywords', content: keywords }
              ]}
            />
            <header className={headerStyles}>
              <nav>
                <div className="info" onClick={handleInfoToggle(onInfoToggle)}>
                  Info
                </div>
                <div className="logo">
                  <img alt="Akme logo" src={logo} />
                </div>
                <div className="contact">
                  <a href="mailto:contact@akmestudio.com">Contact</a>
                </div>
              </nav>
            </header>
          </React.Fragment>
        );
      }}
    />
  );
}

export default Header;
