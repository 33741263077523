export const STYLES = {
  small: {
    closeLeftPadding: '40px',
    borderPadding: '35px',
    topPadding: '30px'
  },
  medium: {
    closeLeftPadding: '55px',
    borderPadding: '50px',
    topPadding: '35px'
  }
};

export const BREAKPOINTS = {
  medium: '420px',
  large: '760px'
};

export const MOBILE_IMAGE_BREAKPOINT = 600;
